@font-face {
    font-family: poppins_reg;
    src: url(../src/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
    font-family: poppins_bold;
    src: url(../src/fonts/Poppins/Poppins-Bold.ttf);
}

.poppins_reg {
    font-family: poppins_reg;
}

.poppins_bold {
    font-family: poppins_bold;
}

.btn-primary {
    border: #719d5d !important;
    background-color: #719d5d !important;
}

 .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    border: #adc9a0 !important;
    background-color: #adc9a0 !important;
}