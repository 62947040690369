.navbar{
   padding: 0 !important;
}

.navbar-height {
    height: 66px !important; 
}

.container-fluid {
   padding: 0 !important;
   margin-left: auto !important;
   margin-right: auto !important;
   margin-bottom: 0;
}

.offcanvas-start{
   width: 80%;
}

@media only screen and (min-width: 600px) {
   .navbar {
    height: 100px !important; 
   }
}

@media only screen and (min-width: 768px) {
   .navbar {
    height: 115px !important; 
  } 
}

@media only screen and (max-width: 990px) {
   .navbar-brand {
      transform: translateX(-50%);
      left: 50%;
      position: absolute;
    }

    .logo{
      width: 80%;
      height: 80%;
      margin-left: 15%;
    }
}