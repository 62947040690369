.jumbotron {
    background-image: linear-gradient(
        rgba(241, 241, 227, 0.781), 
        rgba(32, 30, 30, 0.589)
      ), url("../imgs/forest.jpg");
    background-size: cover;
    height: 80vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    border-radius:10px;
  } 

  .breakline {
    height:100px;
    border-width:0;
    color:gray;
    background-color:rgb(0, 0, 0)
  }

  .learnMoreBtn{
    margin-right: 10px;
    background-color: #161616;
    border: #161616;
  }